<template>
  <div class="car">
    <el-form :inline="true" :model="dataForm">
      <el-form-item label="所属部门" prop="did">
        <el-select
          v-model="dataForm.did"
          clearable
          filterable
          @change="didChange"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="所属班组" prop="tid">
        <el-select v-model="dataForm.tid" clearable filterable>
          <el-option
            v-for="item in teamList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="medium"
      v-loading="dataListLoading"
      style="width: 1200px"
      height="400"
    >
      <el-table-column
        type="index"
        header-align="center"
        align="center"
        label="序号"
        width="70"
      >
      </el-table-column>
      <el-table-column
        prop="departmentName"
        header-align="center"
        align="center"
        label="所属部门"
      >
      </el-table-column>
      <el-table-column
        prop="teamName"
        header-align="center"
        align="center"
        label="所属班组"
      >
      </el-table-column>
      <el-table-column
        prop="employeeName"
        header-align="center"
        align="center"
        label="所属员工"
      >
      </el-table-column>
      <el-table-column
        prop="number"
        header-align="center"
        align="center"
        label="车牌号"
      >
      </el-table-column>
      <el-table-column
        prop="yxlx"
        header-align="center"
        min-width="130"
        align="center"
        label="运行路线"
      >
      </el-table-column>
      <el-table-column
        prop="gzsj"
        header-align="center"
        align="center"
        label="购置时间"
        width="100"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.gzsj ? scope.row.gzsj.split(" ")[0] : "" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="tcrq"
        header-align="center"
        align="center"
        label="退出日期"
        width="100"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.tcrq ? scope.row.tcrq.split(" ")[0] : "" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="sccj"
        header-align="center"
        align="center"
        min-width="130"
        label="生产厂家"
      >
      </el-table-column>
      <el-table-column
        prop="xh"
        header-align="center"
        align="center"
        label="型号"
      >
      </el-table-column>
      <el-table-column
        prop="zws"
        header-align="center"
        align="center"
        label="座位数"
      >
      </el-table-column>
      <el-table-column
        prop="dlyszPic"
        header-align="center"
        align="center"
        label="道路运输证"
      >
        <template slot-scope="scope">
          <div>
            <el-image
              style="height: 50px"
              fit="contain"
              :src="$http.common.pic + scope.row.dlyszPic"
              :preview-src-list="[$http.common.pic + scope.row.dlyszPic]"
            ></el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="xszPic"
        header-align="center"
        align="center"
        label="行驶证"
      >
        <template slot-scope="scope">
          <div>
            <el-image
              style="height: 50px"
              fit="contain"
              :src="$http.common.pic + scope.row.xszPic"
              :preview-src-list="[$http.common.pic + scope.row.xszPic]"
            ></el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间"
        width="100"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.createDate ? scope.row.createDate.split(" ")[0] : "" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0">车辆加入审批中</span>
          <span v-if="scope.row.status == 1">在网</span>
          <span v-if="scope.row.status == 2">已拒绝加入</span>
          <span v-if="scope.row.status == 3">修改车辆信息审批中</span>
          <span v-if="scope.row.status == 4">退出使用</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="100"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            plain
            @click="seeDetailsHandler(scope.row.id)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>

    <see-detail v-if="seeDetail_visible" ref="SeeDetailDom"></see-detail>
  </div>
</template>

<script>
import SeeDetail from "./see-detail.vue";
export default {
  components: {
    SeeDetail,
  },
  data() {
    return {
      dataForm: {},
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],

      departmentList: [], // 部门列表
      teamList: [], // 班组列表
      seeDetail_visible: false, // 查看详情组件显示状态
    };
  },
  methods: {
    init() {
      this.getDataList();
      this.getDepartmentList();
    },

    // 获取数据列表
    getDataList() {
      console.log(123);
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;

      this.$http.car.list(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },

    // 获取部门列表
    getDepartmentList() {
      this.$http.department.list({ page: 1, limit: 99999 }).then((res) => {
        if (res.data) {
          this.departmentList = res.data.list || [];
        } else {
          this.departmentList = [];
        }
      });
    },

    // 获取班组列表
    getTeamList() {
      let teamListDF = {
        did: this.dataForm.did,
        page: 1,
        limit: 99999,
      };

      this.$http.team.list(teamListDF).then((res) => {
        if (res.data) {
          this.teamList = res.data.list || [];
        } else {
          this.teamList = [];
        }
      });
    },

    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },

    // 当前选择的部门发生变化
    didChange() {
      this.teamList = [];
      if (this.dataForm.did) {
        this.getTeamList();
      } else {
        this.dataForm.tid = "";
        this.teamList = [];
      }
    },

    // 打开查看详情组件
    seeDetailsHandler(id) {
      this.seeDetail_visible = true;
      this.$nextTick(() => {
        this.$refs.SeeDetailDom.init(id);
      });
    },
  },
};
</script>